<script setup>
const enlace = process.env.VUE_APP_ENI
</script>

<template>
  <SisdaiNavegacionPrincipal
    :nav-informacion="`Sección: <b>${$route.name}</b>`"
  >
    <template #complementario>
      <a
        class="nav-hipervinculo"
        :href="enlace"
        target="_blank"
        rel="noopener noreferrer"
      >
        <b>IR A ENI</b>
      </a>
    </template>
    <ul class="nav-menu"></ul>
  </SisdaiNavegacionPrincipal>
</template>
