<script>
export default {
  mounted() {
    const cdn_archivos_dominio = process.env.VUE_APP_CDN_ARCHIVOS

    var numero_de_particulas_fondo = 9
    var numero_de_particulas = 6
    var numero_de_particulas_con_historia = 200

    var particulas = new Array(numero_de_particulas)
    var particulas_de_fondo = new Array(numero_de_particulas_fondo)

    var particulas_con_historia = new Array(numero_de_particulas_con_historia)

    const script = function (p5) {
      let imgs_celulas = []
      let imgs_celulas_fondo = []
      p5.preload = () => {
        for (let i = 0; i <= numero_de_particulas; i++) {
          imgs_celulas.push(
            p5.loadImage(cdn_archivos_dominio + `/foco/${i + 1}.webp`)
          )
        }
        for (let i = 0; i <= numero_de_particulas_fondo; i++) {
          imgs_celulas_fondo.push(
            p5.loadImage(cdn_archivos_dominio + `/fondo/${i + 1}.webp`)
          )
        }
      }

      p5.setup = () => {
        const canvas = p5.createCanvas(
          document.getElementById('bg1').clientWidth,
          document.getElementById('bg1').clientHeight
        )
        canvas.parent('bg1')
        //Particulas
        for (let i = 0; i < numero_de_particulas_fondo; i++) {
          particulas_de_fondo[i] = new Particula(
            i + 1,
            p5.random(
              0.1 * p5.max(p5.width, p5.height),
              0.2 * p5.max(p5.width, p5.height)
            ),
            imgs_celulas_fondo[(i + 1) % 11]
          )
        }
        for (let i = 0; i < numero_de_particulas; i++) {
          particulas[i] = new Particula(
            i + 1,
            p5.random(
              0.2 * p5.max(p5.width, p5.height),
              0.25 * p5.max(p5.width, p5.height)
            ),
            imgs_celulas[(i + 1) % 11]
          )
        }

        for (let i = 0; i < numero_de_particulas_con_historia; i++) {
          particulas_con_historia[i] = new particulaConHistoria(
            p5.random(p5.width),
            p5.random(p5.height)
          )
        }
        p5.frameRate(18)
        p5.noStroke()
      }

      p5.draw = () => {
        p5.clear()
        for (let i = 0; i < numero_de_particulas_fondo; i++) {
          particulas_de_fondo[i].actualizarPosicion()
          particulas_de_fondo[i].mostrarParticula()
        }
        for (let i = 0; i < numero_de_particulas_con_historia; i++) {
          particulas_con_historia[i].aplicandoFuerza()
          particulas_con_historia[i].actualizarPosicion()
          particulas_con_historia[i].mostrarParticula()
          particulas_con_historia[i].condicionDeEjes()
        }

        for (let i = 0; i < numero_de_particulas; i++) {
          particulas[i].actualizarPosicion()
          particulas[i].mostrarParticula()
        }
      }

      p5.windowResized = () => {
        p5.resizeCanvas(p5.windowWidth, p5.windowHeight)
        for (let i = 0; i < numero_de_particulas_fondo; i++) {
          particulas_de_fondo[i].size = p5.random(
            0.1 * p5.max(p5.width, p5.height),
            0.2 * p5.max(p5.width, p5.height)
          )
        }
        for (let i = 0; i < numero_de_particulas; i++) {
          particulas[i].size = p5.random(
            0.2 * p5.max(p5.width, p5.height),
            0.25 * p5.max(p5.width, p5.height)
          )
        }
        for (let i = 0; i < numero_de_particulas_con_historia; i++) {
          particulas_con_historia[i].tamanio =
            p5.max(p5.width, p5.height) * 0.01
          particulas_con_historia[i].maxvel =
            p5.max(p5.width, p5.height) * 0.005
          particulas_con_historia[i].pos = p5.createVector(
            p5.random(p5.width),
            p5.random(p5.height)
          )
        }
      }

      //Objetos
      class Particula {
        constructor(indice, tamanio, img) {
          this.velocidad_de_rotacion = p5.random(-15, 15)
          this.rotacion_inicial = p5.random(10)
          this.position = p5.createVector(
            p5.random(p5.width),
            p5.random(p5.height)
          )
          this.size = tamanio
          this.velocity = p5.createVector(p5.random(-1, 1), p5.random(-1, 1))
          this.indice = indice
          this.imagen = img
        }
        actualizarPosicion() {
          this.position.add(this.velocity)
          this.colisionConParedes()
        }
        mostrarParticula() {
          p5.noStroke()
          p5.push()
          p5.translate(
            this.position.x - this.size / 2,
            this.position.y - this.size / 2
          )
          p5.rotate(
            this.rotacion_inicial +
              0.001 * p5.frameCount * this.velocidad_de_rotacion
          )
          p5.imageMode(p5.LEFT)
          p5.image(this.imagen, 0, 0, this.size, this.size)
          p5.pop()
        }
        colisionConParedes() {
          if (this.position.x < 0 || this.position.x > p5.width) {
            this.velocity.x *= -1
          }
          if (this.position.y < 0 || this.position.y > p5.height) {
            this.velocity.y *= -1
          }
        }
      }
      function particulaConHistoria(x, y) {
        this.size = p5.max(p5.width, p5.height) * 0.005
        this.pos = new P5.Vector(x, y) // in processing new PVector(x, y);
        this.vel = new P5.Vector(0, 0) //
        this.acc = new P5.Vector(0, 0) //
        this.history = []

        this.maxvel = p5.max(p5.width, p5.height) * 0.005

        this.actualizarPosicion = function () {
          this.acc.mult(2)
          this.vel.add(this.acc)
          this.vel.limit(this.maxvel)
          this.pos.add(this.vel)
          this.acc.mult(0)

          var hv = p5.createVector(this.pos.x, this.pos.y)

          this.history.push(hv)
          if (this.history.length > 5) {
            this.history.splice(0, 1)
          }
        }

        this.mostrarParticula = function () {
          for (var i = 1; i < this.history.length; i++) {
            p5.stroke(253, 230, 140, p5.map(i, 1, this.history.length, 50, 100))
            p5.line(
              this.history[i - 1].x,
              this.history[i - 1].y,
              this.history[i].x,
              this.history[i].y
            )
          }
        }

        this.aplicandoFuerza = function () {
          var myAngle = p5.random(-360, 360)
          this.acc = P5.Vector.fromAngle(p5.radians(myAngle))
        }

        this.condicionDeEjes = function () {
          if (this.pos.x > p5.width || this.pos.x < 0) {
            this.vel.x *= -1
          }
          if (this.pos.y > p5.height || this.pos.y < 0) {
            this.vel.y *= -1
          }
        }
      }
    }

    // NOTE: Use p5 as an instance mode
    const P5 = require('p5')
    new P5(script)
  },
}
</script>
<template>
  <div
    id="bg1"
    class="a11y-simplificada-hidden"
  >
    <div class="background">
      <span class="lg"></span>
      <span class="lg"></span>
      <span class="lg"></span>
      <span class="lg"></span>
      <span class="lg"></span>
      <span class="lg"></span>
      <span class="sm"></span>
      <span class="sm"></span>
      <span class="sm"></span>
      <span class="sm"></span>
      <span class="sm"></span>
      <span class="sm"></span>
      <span class="sm"></span>
      <span class="sm"></span>
      <span class="sm"></span>
      <span class="sm"></span>
      <span class="sm"></span>
      <span class="sm"></span>
      <span class="sm"></span>
      <span class="sm"></span>
    </div>
  </div>
</template>
<style scoped lang="scss">
#bg1 {
  background: radial-gradient(
    ellipse farthest-corner at 70% 120%,
    #86a0a5,
    #3c5b68 20%,
    #105a66 30%,
    #053158 60%,
    #252832 80%
  );
  overflow: hidden;
}

@keyframes move {
  100% {
    transform: translate3d(0, 0, 1px) rotate(360deg);
  }
}

.background .sm {
  width: 30vmin;
  height: 30vmin;
  border-radius: 30vmin;
  backface-visibility: hidden;
  position: absolute;
  animation: move;
  animation-duration: 45;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  opacity: 0.3;
}

.background .lg {
  width: 50vmin;
  height: 50vmin;
  border-radius: 50vmin;
  backface-visibility: hidden;
  position: absolute;
  animation: move;
  animation-duration: 66;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  opacity: 0.5;
}

.background span:nth-child(0) {
  color: #3c5b68;
  top: 19%;
  left: 13%;
  animation-duration: 14s;
  animation-delay: -7s;
  transform-origin: -6vw 0vh;
  box-shadow: 100vmin 0 13.006814789977362vmin currentColor;
}
.background span:nth-child(1) {
  color: #3c5b68;
  top: 56%;
  left: 82%;
  animation-duration: 10s;
  animation-delay: -37s;
  transform-origin: -5vw -2vh;
  box-shadow: -100vmin 0 13.377883142797735vmin currentColor;
}
.background span:nth-child(2) {
  color: #3c5b68;
  top: 3%;
  left: 8%;
  animation-duration: 45s;
  animation-delay: -26s;
  transform-origin: 9vw -3vh;
  box-shadow: -100vmin 0 13.37900182002191vmin currentColor;
}
.background span:nth-child(3) {
  color: #3c5b68;
  top: 27%;
  left: 2%;
  animation-duration: 43s;
  animation-delay: -18s;
  transform-origin: 1vw -20vh;
  box-shadow: -100vmin 0 13.195543684405454vmin currentColor;
}
.background span:nth-child(4) {
  color: #3c5b68;
  top: 99%;
  left: 45%;
  animation-duration: 52s;
  animation-delay: -31s;
  transform-origin: 8vw -17vh;
  box-shadow: 100vmin 0 12.505503403482379vmin currentColor;
}
.background span:nth-child(5) {
  color: #3c5b68;
  top: 9%;
  left: 45%;
  animation-duration: 39s;
  animation-delay: -18s;
  transform-origin: 13vw -13vh;
  box-shadow: -100vmin 0 12.553730740176398vmin currentColor;
}
.background span:nth-child(6) {
  color: #3c5b68;
  top: 33%;
  left: 73%;
  animation-duration: 7s;
  animation-delay: -18s;
  transform-origin: 1vw -22vh;
  box-shadow: 100vmin 0 12.637063899070805vmin currentColor;
}

.background span:nth-child(7) {
  color: #37519e;
  top: 96%;
  left: 32%;
  animation-duration: 47s;
  animation-delay: -32s;
  transform-origin: -17vw 11vh;
  box-shadow: 40vmin 0 5.797504762549727vmin currentColor;
}
.background span:nth-child(8) {
  color: #053158;
  top: 32%;
  left: 62%;
  animation-duration: 54s;
  animation-delay: -5s;
  transform-origin: 20vw 14vh;
  box-shadow: 40vmin 0 5.7815569951573025vmin currentColor;
}
.background span:nth-child(9) {
  color: #37519e;
  top: 74%;
  left: 76%;
  animation-duration: 32s;
  animation-delay: -30s;
  transform-origin: 18vw -6vh;
  box-shadow: 40vmin 0 5.760454528355747vmin currentColor;
}
.background span:nth-child(10) {
  color: #37519e;
  top: 87%;
  left: 21%;
  animation-duration: 30s;
  animation-delay: -35s;
  transform-origin: -1vw -18vh;
  box-shadow: 40vmin 0 5.362537756878198vmin currentColor;
}
.background span:nth-child(11) {
  color: #105a66;
  top: 79%;
  left: 58%;
  animation-duration: 35s;
  animation-delay: -19s;
  transform-origin: -24vw -24vh;
  box-shadow: -40vmin 0 5.179752799551315vmin currentColor;
}
.background span:nth-child(12) {
  color: #105a66;
  top: 37%;
  left: 64%;
  animation-duration: 55s;
  animation-delay: -37s;
  transform-origin: 21vw -6vh;
  box-shadow: 40vmin 0 5.683580082317413vmin currentColor;
}
.background span:nth-child(13) {
  color: #053158;
  top: 85%;
  left: 36%;
  animation-duration: 49s;
  animation-delay: -29s;
  transform-origin: 25vw 11vh;
  box-shadow: -40vmin 0 5.995832850612223vmin currentColor;
}
.background span:nth-child(14) {
  color: #053158;
  top: 79%;
  left: 58%;
  animation-duration: 8s;
  animation-delay: -42s;
  transform-origin: -5vw 13vh;
  box-shadow: 40vmin 0 5.196163781229746vmin currentColor;
}
.background span:nth-child(15) {
  color: #053158;
  top: 77%;
  left: 40%;
  animation-duration: 48s;
  animation-delay: -14s;
  transform-origin: 25vw -12vh;
  box-shadow: 40vmin 0 5.169785432970711vmin currentColor;
}
.background span:nth-child(16) {
  color: #053158;
  top: 2%;
  left: 18%;
  animation-duration: 39s;
  animation-delay: -30s;
  transform-origin: -17vw -17vh;
  box-shadow: 40vmin 0 5.018643264191227vmin currentColor;
}
.background span:nth-child(17) {
  color: #053158;
  top: 60%;
  left: 16%;
  animation-duration: 23s;
  animation-delay: -30s;
  transform-origin: -17vw 20vh;
  box-shadow: -40vmin 0 5.38731002283359vmin currentColor;
}
.background span:nth-child(18) {
  color: #105a66;
  top: 38%;
  left: 72%;
  animation-duration: 27s;
  animation-delay: -42s;
  transform-origin: 6vw -6vh;
  box-shadow: -40vmin 0 5.870801884629332vmin currentColor;
}
.background span:nth-child(19) {
  color: #105a66;
  top: 95%;
  left: 39%;
  animation-duration: 47s;
  animation-delay: -40s;
  transform-origin: 8vw -23vh;
  box-shadow: -40vmin 0 5.648593199539868vmin currentColor;
}
</style>
