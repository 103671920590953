<script setup>
import InicioPortada from '@/components/InicioPortada.vue'
const cdn_archivos_dominio = process.env.VUE_APP_CDN_ARCHIVOS
const dominio = process.env.VUE_APP_ENI_DOMAIN
const dominio_tox = process.env.VUE_APP_ENI_DOMAIN_TOX
const url_gema = process.env.VUE_APP_GEMA
const ambiente_eni = process.env.VUE_APP_ENI
const cdn_archivos_ldn = cdn_archivos_dominio.replace(
  '/salud/index',
  '/landing'
)

import { onBeforeUnmount, onMounted } from 'vue'

function calHeight() {
  const innerheight = window.innerHeight / 100
  document
    .querySelector(':root')
    .style.setProperty('--vh', `${innerheight.toString()}px`)
}

calHeight()

onMounted(() => {
  window.addEventListener('resize', calHeight)
})

onBeforeUnmount(() => {
  window.addEventListener('resize', calHeight)
})
</script>

<template>
  <main id="principal">
    <div class="contenedor-portada">
      <InicioPortada class="portada" />

      <div class="degradado-negro-abajo">
        <div class="contenido-abajo">
          <div class="contenedor ancho-lectura">
            <h1 class="titulo-eni texto-centrado texto-color-1">Salud</h1>
          </div>
        </div>
      </div>
    </div>
    <div class="contenedor ancho-lectura m-y-10">
      <h2 class="texto-centrado m-y-8-esc m-y-4-mov m-x-auto">
        Ecosistema Nacional Informático de Salud
      </h2>
      <p class="texto-alto">
        El Ecosistema Nacional Informático de Salud (ENI-Salud) ofrece un
        espacio de acceso abierto para el análisis de datos y visualización de
        información generada a partir de los Proyectos Nacionales de
        Investigación e Incidencia (Pronaii) del Programa Nacional Estratégico,
        Pronaces-Salud.
      </p>
      <p class="texto-alto">
        Este recurso público de información está dirigido a todas y todos los
        interesados en esta temática; es una plataforma especialmente útil para
        las y los investigadores, académicos, estudiantes, así como para las y
        los tomadores de decisiones.
      </p>
      <p class="texto-alto">
        El Ecosistema Nacional Informático de Salud está organizado en
        capítulos. Éstos, a su vez, se encuentran asociados a repositorios
        digitales que contienen información, documentos y diversos materiales
        relacionados y/o derivados de investigaciones científicas.
      </p>
    </div>

    <div class="contenedor ancho-fijo m-y-10">
      <div
        class="flex flex-contenido-separado contenedor ancho-fijo flex-columnas-100-al-simplificar"
      >
        <div class="columna-16 m-b-3">
          <a
            class="tarjeta-hipervinculo-externo tarjeta-eni"
            :href="`${dominio_tox}/resa-atoyac/`"
            target="_blank"
            rel="noopener noreferrer"
          >
            <picture class="fondo-imagen">
              <img :src="`${cdn_archivos_dominio}/tarjetas/resa-esc.png`" />
            </picture>
            <div class="degradado-negro-abajo">
              <div class="contenido-abajo">
                <p class="titulo-pagina texto-color-1">
                  Región de Emergencia Sanitaria y Ambiental: Cuenca del Alto
                  Atoyac
                </p>
              </div>
            </div>
          </a>
        </div>
        <div class="columna-16 m-b-3">
          <a
            class="tarjeta-hipervinculo-externo tarjeta-eni"
            :href="`${dominio}/vih/`"
            target="_blank"
            rel="noopener noreferrer"
          >
            <picture class="fondo-imagen">
              <img :src="`${cdn_archivos_dominio}/tarjetas/vih.jpg`" />
            </picture>
            <div class="degradado-negro-abajo">
              <div class="contenido-abajo">
                <p class="titulo-pagina texto-color-1">
                  Red de transmisión y vigilancia de farmacorresistencia del VIH
                  en la CDMX
                </p>
              </div>
            </div>
          </a>
        </div>
        <div class="columna-8-esc m-b-3">
          <a
            class="tarjeta-hipervinculo-externo tarjeta-eni"
            :href="`${dominio}/coronavirus/`"
            target="_blank"
            rel="noopener noreferrer"
          >
            <picture class="fondo-imagen">
              <img :src="`${cdn_archivos_dominio}/tarjetas/covid.jpg`" />
            </picture>
            <div class="degradado-negro-abajo">
              <div class="contenido-abajo">
                <p class="titulo-pagina texto-color-1">
                  Conahcyt frente a la Covid-19
                </p>
              </div>
            </div>
          </a>
        </div>
        <div class="columna-8-esc m-b-3">
          <a
            class="tarjeta-hipervinculo-externo tarjeta-eni"
            :href="`${url_gema}/visualizador?seccion=salud`"
            target="_blank"
            rel="noopener noreferrer"
          >
            <picture class="fondo-imagen">
              <img
                :src="`${cdn_archivos_dominio}/tarjetas/eni-salud-gema.png`"
              />
            </picture>
            <div class="degradado-negro-abajo">
              <div class="contenido-abajo">
                <p class="titulo-pagina texto-color-1">
                  Explora capas del tema salud
                </p>
                <hr />
                <p class="subtitulo-capitulo texto-color-1">
                  Gestor de mapas (Gema)
                </p>
              </div>
            </div>
          </a>
        </div>
        <div class="columna-8-esc m-b-3">
          <a
            class="tarjeta-hipervinculo-externo tarjeta-eni"
            :href="`${ambiente_eni}/radiombligo?tematica=Salud`"
            target="_blank"
            rel="noopener noreferrer"
          >
            <picture class="fondo-imagen">
              <img
                :src="`${cdn_archivos_ldn}/radio-ombligo/ciencia-para-ninas-y-ninos.png`"
              />
            </picture>
            <div class="degradado-negro-abajo">
              <div class="contenido-abajo">
                <p class="titulo-pagina texto-color-1">
                  Ciencia para niñas y niños
                </p>
              </div>
            </div>
          </a>
        </div>
      </div>
    </div>
  </main>
</template>
<style lang="scss">
picture.fondo-imagen {
  img {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
}
.tarjeta-hipervinculo-externo {
  min-height: 320px;
}
</style>
